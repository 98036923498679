@import "../../styles/helpers";

.chat_interface {
  width: 100%;
}

.messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message {
  padding: 5px;
}

.message sup {
  font-size: smaller;
  vertical-align: super;
}

.server span {
  /* Other styles */
  // width: 1px;
  display: inline-block;
  animation: type 0.05s linear; /* .05s duration for each character */
  position: relative;
  animation-fill-mode: backwards;
  overflow: hidden;
  white-space: nowrap;
}

.purple {
  color: #a769c0;
}

.server span sup {
  overflow: visible;
}

.server:last-of-type:after {
  content: "█"; /* The cursor */
  color: #a769c0;
  width: 8px;
  display: inline-block;
  overflow: hidden;
  animation: blink 1s step-end infinite; /* .5s blink interval */
}

@keyframes type {
  0% {
    width: 0;
  }
  100% {
    width: 4px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bubble {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
}

.server .bubble {
  background-color: #eee;
  color: #333;
}

.client .bubble {
  background-color: blue;
  color: white;
}

.client {
  background-color: white;
}

form {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
}

input[type="text"] {
  flex: 1;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px;
  &:focus {
    outline: none;
  }
}

button[type="submit"] {
  background-color: blue;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  &:hover {
    background-color: darkblue;
  }
  &:active {
    background-color: #0044cc;
  }
  &:focus {
    outline: none;
  }
}

.citations {
  font-size: 10px;
}

.hidden {
  display: none;
}
