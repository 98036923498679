@import "../../styles/helpers";
:global .h-100 {
  height: 100%;
}

.widget {
  position: relative;
  display: block;
  border-radius: $widget-border-radius;
  background: $widget-bg-color;
  height: 100%;
}

.title {
  margin-top: 0;
  padding: $widget-padding-vertical $widget-padding-horizontal 0;
  color: $text-color;
}

