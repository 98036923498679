@import "../../styles/helpers";

.profile {
  background: $violet;
  border-radius: $border-radius;
  height: 100%;
  color: $white;
  * > {
    color: $white !important;
  }

}

.profileTitle {
  display: flex;
  margin-bottom: 1rem;
  img {
    width: 90px;
    height: 90px;
    @media (max-width: breakpoint-max(sm)) {
      width: 50px;
      height: 50px;
    }
  }
  div {
    hr {
      width: 100%;
      border: none;
      border-top: 1px solid $base-middle-gray;
      color: $base-middle-gray;
    }
  }
  i {
    color: $white;
  }
}

.badges {
  span {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}

.tile {
  display: flex;
  flex-direction: column;
  margin: 1.5rem auto;
}

.news {
  height: 300px;
  overflow-y: scroll;
}

.chronoWidget {
  height: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  section {
    margin: .5rem 0;
  }
}

.widgetRow {
  height: 100% !important;
}

.cardsWidgetRow {
  height: 100% !important;
  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.widgetOverflow {
  section {
    overflow: auto;
    white-space: nowrap;
  }
  > .headline-1 {
    color: red;
  }
}

.earning {
  color: $pink;
}


